<template>
  <div style="text-align: left">
    <Card style="height:100%">
      <div class="search-con search-con-top">
        <Input clearable placeholder="输入策略名称 搜索" class="search-input" v-model="queryinfo.name"
               @on-enter="namechange"
               @on-change="namechange"/>
        <Button @click="namechange" class="search-btn" type="primary">搜索</Button>
        <slot name="new_btn">
          <Button type="primary" class="search-btn"  @click="showaddmodel">新建</Button>
        </slot>
      </div>
      <div>
        <Table border ref="selection" :columns="columns" :data="data" ></Table>
      </div>
      <br/>
      <template>
        <Page :current="queryinfo.page_num"
              :total="total"
              :page-size-opts="pagesizeopts"
              @on-change="changepage"
              @on-page-size-change="changepagesize"
              show-total
              show-sizer
              style="text-align: right;" />
      </template>
    </Card>
    <Drawer
        :closable="false"
        v-model="showaddroutes"
        @on-visible-change="visiblechange"
        title="请添加告警策略"
        width="800">
      <Form ref="formValidate" :model="formDynamic" :rules="ruleformDynamic" :label-width="80">
        <FormItem label="名称" prop="title" :label-width="80" style="width: 77%">
          <Input v-model="formDynamic.title" placeholder="请输入规则名称"></Input>
        </FormItem>
        <h4>告警规则如下 <Button @click="addmatchers">添加matchers</Button></h4>
        <Divider />
        <div v-for="(item,index) in formDynamic.matchers" :key="index">
          <Row>
            <Col span="7" >
              <FormItem label="" prop="name" >
                <Select v-model="item.name" placeholder="请选择name">
                  <Option v-for="compare in comparekeylist" :value="compare.value" :key="compare.value">{{ compare.label }}</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="7" >
              <FormItem prop="op" >
                <Select v-model="item.op" placeholder="请选择比较">
                  <Option v-for="compare in comparelist" :value="compare.value" :key="compare.value">{{ compare.label }}</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="7" >
              <FormItem prop="value" >
                <Input  v-model="item.value" placeholder="请输入value" style="width: 100%" ></Input >
              </FormItem>
            </Col>
            <Col span="2" offset="1">
              <Button @click="handleRemove(index)">Delete</Button>
            </Col>
          </Row>
        </div>
        <Divider />
        <FormItem label="聚合等待" prop="group_interval">
          <InputNumber  v-model="formDynamic.group_interval" placeholder="请输入group_interval" style="width: 80%" ></InputNumber >
        </FormItem>
        <FormItem label="通知间隔" prop="repeat_interval">
          <InputNumber  v-model="formDynamic.steps.repeat_interval" placeholder="请输入repeat_interval" style="width: 80%" ></InputNumber >
        </FormItem>
        <Row>
          <Col span="10" >
            <FormItem  prop="notify_immediately" >
              <Checkbox v-model="notify_immediately">立即通知</Checkbox>
            </FormItem>
          </Col>
          <Col span="10" >
            <FormItem  prop="notify_resolved" >
              <Checkbox v-model="notify_resolved">告警恢复</Checkbox>
            </FormItem>
          </Col>
        </Row>
        <FormItem label="通知者" prop="receiver" :label-width="80" style="width: 77%">
          <Select
              v-model="formDynamic.steps.receiver"
              filterable
              :remote-method="remoteMethod1"
              :loading="loading1"
              placeholder="通知者">
            <Option v-for="receiver in receiverlist" :value="receiver.id" :key="receiver.id">{{ receiver.name }}</Option>
          </Select>
        </FormItem>
        <CheckboxGroup  v-model="formDynamic.media" :label-width="80" >通知方式 &nbsp;&nbsp;&nbsp;&nbsp;
          <Checkbox label="email">邮件</Checkbox>&nbsp;&nbsp;&nbsp;&nbsp;
          <Checkbox label="wechat_robot">微信机器人</Checkbox>
        </CheckboxGroup><br />
        <FormItem>
          <Button @click="addform('formValidate')" type="primary">确定</Button>&nbsp;
          <Button @click="addcancel">取消</Button>
        </FormItem>
      </Form>
    </Drawer>
    <Modal
        v-model="delmodel"
        title="确定删除策略"
        @on-ok="okdelete"
        @on-cancel="delmodel = false">
      <p>正在进行删除操作</p>
      <p>删除通知： {{delname.title}}</p>
    </Modal>
  </div>
</template>

<script>
import Notice from "@/components/baseNotice";
import {get,post,del,getAlertAPIUrl} from "@/api/http.js";
export default {
  name: "routes",
  data () {
    return {
      delname: {
        id:'',
        title:'',
      },
      delmodel: false,
      notify_resolved:true,
      notify_immediately:true,
      loading1: false,
      queryinfo: {
        page_num: 1,
        page_size: 10,
        name:"",
      },
      total: 0,
      pagesizeopts: [10, 30, 50, 100],
      showaddroutes: false,
      queryreceiversinfo: {
        name: '',
        page_num: 1,
        page_size: 10,
      },
      comparekeylist: [
        {value: "pp_alert_name",label:"告警名称"},
        {value: "pp_severity",label:"告警等级"},
        {value: "pp_message",label:"告警描述"},
        {value: "pp_check_item",label:"检查项"},
        {value: "pp_source",label:"告警来源"},
        {value: "pp_object",label:"告警对象"},
        {value: "pp_business",label:"业务"},
      ],

      comparelist: [
        {value:0,label:"等于"},
        {value:1,label:"不等于"},
        {value:2,label:"正则匹配"},
        {value:3,label:"正则不匹配"},
      ],
      receiverlist: [],
      data: [],
      columns: [
        {type: 'selection', width: 60, align: 'center'},
        {title: '策略名称', key: 'title'},
        {title: '聚合等待时间', key: 'group_interval'},
        {title: '恢复通知', key: 'notify_resolved',
          render: (h,{row}) =>{
            return h('div',this.notify_resolved_bool(row.notify_resolved))
          }

        },
        {title: '是否立即通知', key: 'notify_immediately',
          render: (h,{row}) =>{
            return h('div',this.notify_immediately_bool(row.notify_immediately))
          }
        },
        {title: '创建时间', key: 'create_time',
          render: (h,{row}) =>{
            return h('div',this.time_str(row.create_time * 1000))
          }
        },
        {title: '更新时间', key: 'update_time',
          render: (h,{row}) =>{
            return h('div',this.time_str(row.update_time * 1000))
          }
        },
        {
          title: '操 作',
          key: 'action',
          width: 150,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('a', {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.showupdatedrawer(params.row)
                  }
                }
              }, '修 改 '),
              h('a', {
                on: {
                  click: () => {
                    this.isdelete(params.row)
                  }
                }
              }, '删 除')
            ]);
          }
        }

      ],
      ruleformDynamic: {
        title: [
          { required: true, message: '策略名称不能为空'}
        ],
        group_interval: [
          { required: true, message: '聚合等待时间'}
        ],
        notify_resolved: [
          { required: true, message: '告警恢复发送通知'}
        ],
        notify_immediately: [
          { required: true, message: '告警是否紧急通知'}
        ],
      },
      index: 1,
      formDynamic: {
        group_interval: 300,
        // notify_resolved: true,
        // notify_immediately: true,
        matchers: [
          {
            op: '',
            name: "",
            value: "",
          }
        ],
        steps:{
          "receiver":"",
          "repeat_interval":360
        },
        media: []
      }
    }
  },
  methods: {
    timestampToTime (cjsj) {
      var date = new Date(cjsj) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-'
      var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-'
      var D = date.getDate() + ' '
      var h = date.getHours() + ':'
      var m = date.getMinutes() + ':'
      var s = date.getSeconds()
      return Y+M+D+h+m+s
    },
    time_str(timestamp){
      return (this.timestampToTime (timestamp))
    },

    notify_resolved_bool(value) {
      if (value===1) {
        return true
      } else {
        return false
      }
    },

    notify_immediately_bool(value) {
      if (value===1) {
        return true
      } else {
        return false
      }
    },

    namechange () {
      this.queryinfo.name = this.queryinfo.name.trim()
      this.getroutelist(this.queryinfo)
    },

    isdelete(row){
      this.delmodel = true
      this.delname = {id:row.id,title:row.title}
    },

    okdelete () {
      const url = getAlertAPIUrl(`/api/v1/routes?ids=${this.delname.id}`);
      del(url).then((resp)=>{
        if (resp["code"]=== 200){
          this.getroutelist(this.queryinfo)
          this.$Message.success('删除成功');
        } else {
          this.$Message.error("删除失败，请稍后再试")
        }
      })
    },

    showaddmodel(){
      this.showaddroutes = true
      this.formDynamic = {
        group_interval: 300,
        notify_resolved: true,
        notify_immediately: true,
        matchers: [
          {
            op: '',
            name: "",
            value: "",
          }
        ],
        steps:{
          "receiver":"",
          "repeat_interval":3600*24
        },
      }
    },
    remoteMethod1 (query) {
      if (query !== '') {
        this.loading1 = true;
        this.queryreceiversinfo.name = query
        setTimeout(() => {
          this.loading1 = false;
          this.getreceivers(this.queryreceiversinfo)}, 200);
      } else {
        this.receiverlist = [];
      }
    },

    getreceivers(queryreceiversinfo) {
      const url = getAlertAPIUrl("/api/v1/receivers");
      get(url,queryreceiversinfo).then((resp)=>{
        if (resp["code"] === 200){
          this.receiverlist = resp["data"]["items"]
        } else {
          this.$Message.error("请求失败，请稍后再试")
        }
      })
    },

    visiblechange(data){
      if (data) {
        this.getreceivers(this.queryreceiversinfo)
      }
    },
    getroutelist(queryinfo) {
      const url = getAlertAPIUrl("/api/v1/routes");
      get(url,queryinfo).then((resp)=>{
        if (resp["code"] === 200) {
          this.total = resp["data"]["total"]
          if (!resp["data"]["items"]){
            this.data = []
          } else {
            this.data = resp["data"]["items"]
          }
        } else {
          Notice.error("请求失败，请稍后再试")
        }
      })
    },

    changepage (page) {
      this.queryinfo.page_num = page
      this.getroutelist(this.queryinfo)
    },

    changepagesize(pagesize) {
      this.queryinfo.page_size = pagesize
      this.getroutelist(this.queryinfo)
    },

    addmatchers() {
      const obj = {op: '', name: "", value: ""}
      this.formDynamic.matchers.push(obj)
    },
    handleRemove (index) {
      if (index !== 0) {
        for (let id=0;id<this.formDynamic.matchers.length;id++){
          if (index === id) {
            this.formDynamic.matchers.splice(id)
          }
        }
      }

    },

    addcancel () {
      this.showaddroutes = false
    },

    showupdatedrawer(row) {
      this.showaddroutes = true
      if (row.notify_immediately === 1) {
        this.notify_immediately = true
      } else {
        this.notify_immediately = false
      }

      if (row.notify_resolved === 1) {
        this.notify_resolved = true
      } else {
        this.notify_resolved = false
      }
      this.formDynamic = row
    },

    addrule(data){
      const url =  getAlertAPIUrl("/api/v1/routes");
      post(url,data).then((resp)=>{
        if (resp.code===200){
          this.$Message.success('Success!');
          this.showaddroutes = false
          this.getroutelist(this.queryinfo)
        } else {
          this.$Message.error("请稍后再试");
        }
      }).cache((e)=>{
        this.$Message.error("请稍后再试");
      })
    },

    addform (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          if (this.notify_resolved) {
            this.formDynamic.notify_resolved = 1
          } else {
            this.formDynamic.notify_resolved = 2
          }
          if (this.notify_immediately) {
            this.formDynamic.notify_immediately = 1
          } else {
            this.formDynamic.notify_immediately = 2
          }
          this.addrule(this.formDynamic)
        } else {
          this.$Message.error('请完善表弟信息');
        }
      })
    },
  },
  created() {
    this.getroutelist(this.queryinfo)
  }
}
</script>

<style lang="less" scoped>
.search-con {
  padding: 10px 0;

  .search {
    &-col {
      display: inline-block;
      width: 200px;
    }

    &-input {
      display: inline-block;
      width: 250px;
      margin-left: 2px;
    }

    &-btn {
      margin-left: 2px;
    }
  }
}
</style>
